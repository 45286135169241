// extracted by mini-css-extract-plugin
export var PDFButton = "Button-module--PDFButton--7816f";
export var PopupButton = "Button-module--PopupButton--3a66f";
export var PrimaryButton = "Button-module--PrimaryButton--aabf7";
export var SecondaryButton = "Button-module--SecondaryButton--63792";
export var TabPrimaryButton = "Button-module--TabPrimaryButton--37f60";
export var TabPrimaryButtonActive = "Button-module--TabPrimaryButtonActive--61cdf";
export var TagBanner = "Button-module--TagBanner--7d257";
export var TagBannerActive = "Button-module--TagBannerActive--40684";
export var TagBannerBlackColor = "Button-module--TagBannerBlackColor--9dfad";
export var ViewMoreButton = "Button-module--ViewMoreButton--221cc";
export var ViewMoreButtonActive = "Button-module--ViewMoreButtonActive--a8e5f";