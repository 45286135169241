import React, { useState, useCallback } from "react";
import ConfirmationPopUp from "./ConfirmationPopUp";

const useConfirmationPopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [resolver, setResolver] = useState({ resolve: null, reject: null });

  const showConfirmation = useCallback(() => {
    setIsVisible(true);
    return new Promise((resolve, reject) => {
      setResolver({ resolve, reject });
    });
  }, []);

  const handlePop = (response) => {
    if (response === "yes") {
      resolver.resolve(true);
    } else {
      resolver.reject(false);
    }
    setIsVisible(false);
    setResolver({ resolve: null, reject: null });
  };

  const ConfirmationPopUpComponent = isVisible ? (
    <ConfirmationPopUp handelPop={handlePop} />
  ) : null;

  return [showConfirmation, ConfirmationPopUpComponent];
};

export default useConfirmationPopup;
