import React from "react";
import Layout from "./src/utils/components/layout";


export const onClientEntry = () => {
  loadScript();
};

const loadScript = () => {
  if (!document.getElementById("kpointvideoLoader")) {
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.id = "kpointvideoLoader";
    script.src = "https://assets.kpoint.com/orca/media/embed/videofront-vega.js";
    document.body.appendChild(script);
  }
};

export const wrapPageElement = ({ element }) => {
  return <Layout>{element}</Layout>;
};
