import React, { useEffect, useRef, useState } from "react";
import * as styles from "./css/Header.module.css";
import { Link, graphql, useStaticQuery } from "gatsby";
import { useLocation } from "@gatsbyjs/reach-router";
import {
  IsEmbedded,
  RenderSvg,
  ScreenSize,
  baseUrl,
  playORPauseVideo,
} from "../../helpers/helper";
import MenuBar from "./MobileNav";
import { Popup } from "../../../components/popup";
import { ProductsPopup } from "../../../components/productsPopup";

const Header = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [openPopup, setOpenPopup] = useState(null);
  const [openProductsPopup, setOpenProductsPopup] = useState(null);
  const MenuRef = useRef(null);
  const headerRef = useRef(null);
  const menuDotRef = useRef(null);
  const connectButtonRef = useRef(null);
  const companyLogoRef = useRef(null);
  const location = useLocation();

  const { allStrapiHeaderPage } = useStaticQuery(graphql`
    query headerSection {
      allStrapiHeaderPage {
        nodes {
          internal {
            content
          }
          header {
            companylogo {
              localFile {
                publicURL
                name
              }
            }
          }
        }
      }
    }
  `);

  const { internal } = allStrapiHeaderPage?.nodes[0] || {};
  const content = internal?.content ? JSON.parse(internal.content) : null;
  const {
    headerButton,
    headermenu,
    privaryTerms,
    copyRight,
    socialLinks,
    companyLogoMenu,
  } = content?.header || {};

  const companylogo =
    allStrapiHeaderPage?.nodes[0]?.header?.companylogo?.localFile;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      headerRef.current.style.background =
        prevScrollPos > 100
          ? "rgba(255, 255, 255, 0.6)"
          : "rgba(255, 255, 255, 0.6)";
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    if (openPopup) {
      playORPauseVideo({
        id: "BannerClickId",
        status: "open",
      });
    } else {
      playORPauseVideo({
        id: "BannerClickId",
        status: "close",
      });
    }
  }, [openPopup]);
  const handleMenu = () => {
    const menuDot = menuDotRef.current;
    const menuRef = MenuRef.current;
    if (!menuRef.classList.contains(styles?.hiddenActive)) {
      menuDot.classList.remove(styles?.MenuActive);
      connectButtonRef.current.style.opacity = "1 ";
      menuRef.classList.add(styles?.hiddenActive);
    } else {
      menuRef.classList.remove(styles?.hiddenActive);
      connectButtonRef.current.style.opacity = "0";
      menuDot.classList.add(styles?.MenuActive);
    }
  };

  const handleProductsClick = () => {
    setOpenProductsPopup(true);
    if (
      MenuRef.current &&
      !MenuRef.current.classList.contains(styles?.hiddenActive)
    ) {
      handleMenu();
    }
  };

  //usecase will be removed and products component will come
  const componentOrder = [
    "home",
    "usecases",
    "platform",
    "about",
    "resources",
    "careers",
    "contact-us",
  ];

  const sortedContent = [...headermenu?.items].sort((a, b) => {
    return componentOrder.indexOf(a.slug) - componentOrder.indexOf(b.slug);
  });

  return IsEmbedded() ? (
    <></>
  ) : (
    <>
      <div
        className={`${styles?.headerWrapper} ${
          visible ? "" : styles?.HideHeader
        } myheaderClass`}
        ref={headerRef}
      >
        <header className={`${styles?.headerContainer}  `}>
          {companylogo && (
            <Link className={styles?.logo} to="/">
              <img
                ref={companyLogoRef}
                src={`${companylogo?.publicURL}`}
                alt={"logo"}
              />
            </Link>
          )}
          <nav className={styles?.navMenuLinks}>
            <ul className={styles?.pageLink}>
              {sortedContent?.map((data, index) => {
                const { slug: pageName, page } = data;
                const linkPath = pageName === "home" ? "/" : `/${pageName}`;
                const isCurrentPage = location.pathname === linkPath + "/";

                let displayTitle = page?.Title || pageName;
                if (
                  displayTitle === "usecases" ||
                  displayTitle === "Use Cases"
                ) {
                  displayTitle = "products";
                }

                if (displayTitle.toLowerCase() === "products") {
                  return (
                    <li
                      key={index}
                      className={isCurrentPage ? styles?.NavVisited : ""}
                    >
                      <a href="#" onClick={handleProductsClick}>
                        {displayTitle}
                        <RenderSvg
                          IconName="angle-down"
                          IconType="light"
                          style={{
                            height: "18px",
                            width: "18px",
                            transform: "translate(5px, 5px)",
                          }}
                        />
                      </a>
                    </li>
                  );
                }

                return (
                  <li
                    key={index}
                    className={
                      pageName === "home"
                        ? `${styles?.HomeLinkNav} ${
                            isCurrentPage ? styles?.NavVisited : ""
                          }`
                        : `${isCurrentPage ? styles?.NavVisited : ""}`
                    }
                  >
                    <Link to={linkPath}>{displayTitle}</Link>
                  </li>
                );
              })}
            </ul>
          </nav>

          {headerButton && (
            <div className={styles?.HeaderBtnSection}>
              <div ref={connectButtonRef} className={styles?.rightSection}>
                <div
                  onClick={() => {
                    setOpenPopup(true);
                  }}
                  className={`${styles?.headerConnectBtn} body-sm`}
                >
                  {headerButton?.label}
                </div>
              </div>
              <div className={styles?.dotWrap} onClick={() => handleMenu()}>
                <div ref={menuDotRef} className={styles?.dot}></div>
              </div>
            </div>
          )}
        </header>
      </div>
      <div
        ref={MenuRef}
        className={`${styles?.MenuContainer} ${styles?.hiddenActive}`}
      >
        <MenuBar
          headermenu={headermenu}
          polics_terms={privaryTerms}
          copyRights={copyRight}
          SocialMediaLinks={socialLinks}
          companylogo={companylogo}
          companyLogoMenu={companyLogoMenu}
          handleMenu={handleMenu}
          styles={styles}
          visible={visible}
          handleProductsClick={handleProductsClick}
        />
      </div>
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        contact={true}
        isMobile={ScreenSize.isMobile()}
        isVertical={true}
        header
      />

      <ProductsPopup
        openProductsPopup={openProductsPopup}
        setOpenProductsPopup={setOpenProductsPopup}
        scheduleDemo
        isMobile={ScreenSize.isMobile()}
        header
      />
    </>
  );
};

export default Header;
