import React from "react";
import * as styles from "./backgroundWave.module.css";
export default function BackgroundWave({ bgColor, height, IsEmbedded }) {
  const getBgClass = () => {
    switch (bgColor) {
      case "red":
        return styles["redGradient"];
      case "green":
        return styles["greenGradient"];
      case "grey":
        return styles["greyGradient"];
      default:
        return styles["blueGradient"];
    }
  };

  const getColorCode = (color) => {
    const colorMapping = {
      red: "#de5045",
      green: "#2dc582",
      blue: "#009bff",
      grey: "#D8D8D8",
    };
    return colorMapping[color] || "#009bff";
  };

  return (
    <>
      <div className={styles.blur}></div>
      <div className={`${styles.grad} ${getBgClass()}`}>
        <div className={styles.waves}>
          <svg
            className={styles.wave1}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill={getColorCode(bgColor)}
              fillOpacity="0.4"
              d="M0,128L120,144C240,160,480,192,720,197.3C960,203,1200,181,1320,170.7L1440,160L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
            ></path>
          </svg>
          <svg
            className={styles.wave2}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill={getColorCode(bgColor)}
              fillOpacity="0.6"
              d="M0,256L120,224C240,192,480,128,720,117.3C960,107,1200,149,1320,170.7L1440,192L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
            ></path>
          </svg>
          <svg
            className={styles.wave3}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill={getColorCode(bgColor)}
              fillOpacity="0.4"
              d="M0,160L120,149.3C240,139,480,117,720,138.7C960,160,1200,224,1320,256L1440,288L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
            ></path>
          </svg>
        </div>
      </div>
    </>
  );
}
