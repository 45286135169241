import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Img } from "react-image";

const RenderMedia = ({ mediaName, folder, className, styles }) => {
  const data = useStaticQuery(graphql`
    query {
      allImages: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        nodes {
          relativePath
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              layout: CONSTRAINED # Specify the layout here, choose the appropriate one for your use case
            )
          }
        }
      }
    }
  `);

  if (!mediaName) return null;
  const imagePath = folder ? `${folder}/${mediaName}` : mediaName;

  const image = data.allImages.nodes.find((node) => {
    if (node.relativePath === imagePath) {
      return node;
    }
  });

  if (!image) {
   
    return null;
  }

  const SvgData = image.extension === "svg" ? image.publicURL : false;
  const Mp4Data = image.extension === "mp4" ? image.publicURL : false;
  const imgData = image.childImageSharp
    ? image.childImageSharp.gatsbyImageData
    : null;
  if (mediaName && mediaName !== "undefined") {
    return SvgData ? (
      <Img style={styles} src={SvgData} alt={mediaName} className={className} />
    ) : Mp4Data ? (
      <video
        style={styles}
        autoPlay
        loop
        muted
        playsInline
        controls={false}
        className={className}
      >
        <source src={Mp4Data} type="video/mp4" />
      </video>
    ) : (
      <GatsbyImage
        style={styles}
        image={imgData}
        alt={mediaName || "img"}
        className={className}
      />
    );
  } else {
    return <></>;
  }
};

export default RenderMedia;
