import React, { useRef, useState } from "react";
import { KpointPlayerContext } from "./KpointPlayerContext";

const KpointPlayerProvider = ({ children }) => {
  const [players, setPlayers] = useState([]);
  const isMuteRef = useRef(true);
  const bindEvents = (player) => {
    player?.addEventListener("muted", () => {
      if (isMuteRef.current) return;
      muteAll();
      isMuteRef.current = true;
    });
    player?.addEventListener("unmuted", () => {
      if (!isMuteRef.current) return;
      unMuteAll();
      isMuteRef.current = false;
    });
  };

  const addPlayer = (player) => {
    setPlayers((prevPlayers) => {
      const newPlayers = [...prevPlayers, player];
      return newPlayers;
    });

    bindEvents(player);
  };

  const removePlayer = (player) => {
    setPlayers((prevPlayers) => {
      const newPlayers = prevPlayers.filter((p) => p !== player);
      return newPlayers;
    });
  };

  const muteAll = () => {
    setPlayers((prevPlayers) => {
      prevPlayers.forEach((player) => {
        player?.mute();
      });
      return [...prevPlayers];
    });
  };

  const unMuteAll = () => {
    setPlayers((prevPlayers) => {
      prevPlayers.forEach((player) => {
        player?.unmute();
      });
      return [...prevPlayers];
    });
  };
  return (
    <KpointPlayerContext.Provider
      value={{
        players,
        isMuteRef,
        addPlayer,
        removePlayer,
      }}
    >
      {children}
    </KpointPlayerContext.Provider>
  );
};

export default KpointPlayerProvider;
