import React, { useRef, useState, memo, useEffect } from "react";
import { baseUrl, isAllowedDomain } from "../../utils/helpers/helper";
import * as styles from "./css/ContactForm.module.css";
import { RenderSvg } from "../../utils/helpers/helper";
import useConfirmationPopup from "./useConfirmationPopUp";

const isValidEmail = (email) => {
  if (!email) return false;
  return (
    email.includes("@") &&
    email.split("@")[1] &&
    email.split("@")[1].split(".")[1] &&
    email.split("@")[1].split(".")[1]?.length > 1 &&
    isAllowedDomain(email)
  );
};

const isValidPhoneNumber = (phoneNumber) => {
  // Remove any non-digit characters
  const digits = phoneNumber.replace(/\D/g, "");

  // Check if it's a valid Indian mobile number
  if (digits.length === 10) {
    // 10-digit number
    return /^[6-9]\d{9}$/.test(digits);
  } else if (digits.length === 11 && digits.startsWith("0")) {
    // 11-digit number starting with 0
    return /^0[6-9]\d{9}$/.test(digits);
  } else if (digits.length === 12 && digits.startsWith("91")) {
    // 12-digit number starting with 91
    return /^91[6-9]\d{9}$/.test(digits);
  } else if (digits.length === 13 && digits.startsWith("91")) {
    // 13-digit number starting with +91 (+ is removed in digits)
    return /^91[6-9]\d{9}$/.test(digits);
  }

  return false;
};

const ContactForm = memo(
  ({
    isMobile,
    handelContactForm,
    handelCheckFormIsFilled = () => {},
    formIsFilled,
    header,
    contactForm,
    openPopup,
  }) => {
    const [success, setSuccess] = useState(null);
    const submitButtonRef = useRef(null);
    const formRef = useRef(null);
    const [showConfirmation, ConfirmationPopUpComponent] =
      useConfirmationPopup();

    const inputRef = useRef(null);

    useEffect(() => {
      if (openPopup === true || contactForm === true) {
        inputRef.current.focus();
      }
    }, [openPopup, contactForm]);

    const handleSubmit = async (event) => {
      event.preventDefault();
      submitButtonRef.current.innerHTML = "Submitting...";

      const formData = new FormData(event.target);
      const phoneNumber = formData.get("PhoneNumber").trim();
      const yourBusinessEmail = formData.get("Email").trim();
      const textarea = formData.get("textArea").trim();

      // Reset custom validity
      event.target.Email.setCustomValidity("");
      event.target.PhoneNumber.setCustomValidity("");
      event.target.textArea.setCustomValidity("");

      // Check for errors
      if (!isValidEmail(yourBusinessEmail)) {
        event.target.Email.setCustomValidity(
          "Please enter a valid business email Id"
        );
        event.target.Email.reportValidity();
        submitButtonRef.current.innerHTML = "Submit";
        return;
      }

      if (!isValidPhoneNumber(phoneNumber)) {
        event.target.PhoneNumber.setCustomValidity(
          "Please enter a valid Indian phone number"
        );
        event.target.PhoneNumber.reportValidity();
        submitButtonRef.current.innerHTML = "Submit";
        return;
      }

      if (!textarea) {
        event.target.textArea.setCustomValidity("Please enter your message");
        event.target.textArea.reportValidity();
        submitButtonRef.current.innerHTML = "Submit";
        return;
      }

      try {
        const response = await fetch(`${baseUrl}/api/contact-forms`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              PhoneNumber: phoneNumber,
              YourBusinessEmail: yourBusinessEmail,
              textArea: textarea,
            },
          }),
        });

        if (response.ok) {
          submitButtonRef.current.innerHTML = "Submitted";
          setSuccess("Contact created successfully!");
          handelCheckFormIsFilled(false);
          // Close the form after a short delay
          setTimeout(() => {
            closeForm(true);
          }, 2000); // 2 second delay
        } else {
          const errorMessage = await response.text();
          const { error } = JSON.parse(errorMessage);
          if (error && error.message.includes("yourBusinessEmail")) {
            event.target.Email.setCustomValidity(
              "Please enter a valid business email Id"
            );
            event.target.Email.reportValidity();
          } else {
            throw new Error(error?.message || "An error occurred");
          }
        }
      } catch (error) {
        console.error("Error creating contact:", error.message);
      } finally {
        submitButtonRef.current.innerHTML = "Submit";
      }
    };

    const handleInputChange = (e) => {
      e.target.setCustomValidity(""); // Clear any custom validity message
      const isFilled = formRef.current;
      handelCheckFormIsFilled(true);
    };

    const clearForm = () => {
      if (formRef.current) {
        formRef.current.reset();
      }
      setSuccess(null);
      handelCheckFormIsFilled(false);
    };

    const closeForm = async (isFilled = false) => {
      if (formIsFilled && isFilled === false) {
        try {
          await showConfirmation();
          clearForm();
          handelContactForm(false);
        } catch {
          console.warn("Form close action was cancelled by the user.");
        }
      } else {
        clearForm();
        handelContactForm(false);
      }
    };

    return (
      <>
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className={styles?.contactForm}
          noValidate
        >
          <div className={styles.mainContainer}>
            {!header && (
              <div
                className={styles.closeIcon}
                onClick={() => {
                  closeForm();
                }}
              >
                <RenderSvg
                  IconName="times"
                  IconType="light"
                  style={{
                    height: "14px",
                    fill: "black",
                  }}
                />
              </div>
            )}
            <div className={styles.ContactFormWrap}>
              <div className={styles?.contactFormInput}>
                <input
                  ref={inputRef}
                  type="email"
                  name="Email"
                  placeholder="Business Email*"
                  onChange={handleInputChange}
                  required
                  autoComplete="off"
                />
                <input
                  type="tel"
                  name="PhoneNumber"
                  placeholder="Phone Number*"
                  onChange={handleInputChange}
                  required
                  autoComplete="off"
                  pattern="(\+91|0)?[6-9]\d{9}"
                  title="Please enter a valid phone number"
                />
                <div className={styles?.contactTextArea}>
                  <textarea
                    name="textArea"
                    rows={1}
                    cols={2}
                    onChange={handleInputChange}
                    placeholder={
                      isMobile
                        ? "How can interactive videos bring value to you?"
                        : "How can interactive videos bring value to you?"
                    }
                    required
                    autoComplete="off"
                  />
                  <button
                    ref={submitButtonRef}
                    className={`${styles?.ContactBtn} banner-secondary-sm`}
                    type="submit"
                  >
                    Send
                  </button>
                </div>
              </div>
              {success && (
                <div
                  className={styles.successMessage}
                  style={{ color: "green" }}
                >
                  {success}
                </div>
              )}
            </div>
          </div>
          <div>{ConfirmationPopUpComponent}</div>
        </form>
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.isMobile === nextProps.isMobile &&
    prevProps.formIsFilled === nextProps.formIsFilled &&
    prevProps.header === nextProps.header &&
    prevProps.openPopup === nextProps.openPopup
);

export default ContactForm;
