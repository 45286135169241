// extracted by mini-css-extract-plugin
export var closeButton = "EmailGate-module--closeButton--2f220";
export var container = "EmailGate-module--container--49e30";
export var content = "EmailGate-module--content--ff40b";
export var emailForm = "EmailGate-module--emailForm--c7d52";
export var emailInput = "EmailGate-module--emailInput--fcf0d";
export var error = "EmailGate-module--error--81e1f";
export var messageContainer = "EmailGate-module--messageContainer--282b6";
export var submitButton = "EmailGate-module--submitButton--ee931";
export var success = "EmailGate-module--success--c41bc";
export var title = "EmailGate-module--title--cc33b";