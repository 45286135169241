// extracted by mini-css-extract-plugin
export var embedCode = "KpointPlayer-module--embedCode--42ab4";
export var image = "KpointPlayer-module--image--cef36";
export var kpointBlue = "KpointPlayer-module--kpoint-blue--1b2d4";
export var kpointGreen = "KpointPlayer-module--kpoint-green--00cb5";
export var kpointRed = "KpointPlayer-module--kpoint-red--cad04";
export var kpointSpinner = "KpointPlayer-module--kpoint-spinner--2bbf8";
export var kpointSpinner__dot = "KpointPlayer-module--kpoint-spinner__dot--4bc39";
export var markupTemplateContainer = "KpointPlayer-module--markupTemplateContainer--b3fa3";
export var notLoaded = "KpointPlayer-module--notLoaded--c1c48";
export var playerOverlay = "KpointPlayer-module--playerOverlay--d137e";
export var spin78236 = "KpointPlayer-module--spin78236--d70d9";
export var thumbnail = "KpointPlayer-module--thumbnail--579ec";
export var videoContainer = "KpointPlayer-module--videoContainer--3faef";
export var wobble1 = "KpointPlayer-module--wobble1--a8e08";
export var wobble2 = "KpointPlayer-module--wobble2--07f43";