// extracted by mini-css-extract-plugin
export var GatsbyImageCircleImage = "cards-module--GatsbyImageCircleImage--772ad";
export var GatsbyImageContainer = "cards-module--GatsbyImageContainer--6350b";
export var PlatformBannerCard = "cards-module--PlatformBannerCard--d759b";
export var PlatformBannerCardBg = "cards-module--PlatformBannerCardBg--3e05c";
export var PlatformBannerCardSubText = "cards-module--PlatformBannerCardSubText--86c8c";
export var PlatformBannerCardText = "cards-module--PlatformBannerCardText--59fda";
export var PlatformBannerCardTextDiv = "cards-module--PlatformBannerCardTextDiv--64da6";
export var PrimaryCrad = "cards-module--PrimaryCrad--2cd54";
export var PrimaryCradActive = "cards-module--PrimaryCradActive--f2125";
export var PrimaryCradEmbed = "cards-module--PrimaryCradEmbed--45cd7";
export var PrimaryCradImg = "cards-module--PrimaryCradImg--417e1";
export var PrimaryCradText = "cards-module--PrimaryCradText--0cf5c";
export var PrimaryCradTitleText = "cards-module--PrimaryCradTitleText--7b0c2";
export var PrimaryCradVideo = "cards-module--PrimaryCradVideo--bd90b";
export var PrimarySquareCard = "cards-module--PrimarySquareCard--c562a";
export var PrimarySquareCardEmbed = "cards-module--PrimarySquareCardEmbed--c775a";
export var PrimarySquareCardImg = "cards-module--PrimarySquareCardImg--61b7e";
export var PrimarySquareCardOverlayDiv = "cards-module--PrimarySquareCardOverlayDiv--19363";
export var PrimarySquareCardOverlayText = "cards-module--PrimarySquareCardOverlayText--73f42";
export var PrimarySquareCardTitle = "cards-module--PrimarySquareCardTitle--98470";
export var PrimarySquareCardVideo = "cards-module--PrimarySquareCardVideo--0ef71";
export var SecondaryCard = "cards-module--SecondaryCard--153f9";
export var SecondaryCardEmbed = "cards-module--SecondaryCardEmbed--562d1";
export var SecondaryCardImg = "cards-module--SecondaryCardImg--ded6f";
export var SecondaryCardVideo = "cards-module--SecondaryCardVideo--862ca";
export var SecondaryCircleCardEmbed = "cards-module--SecondaryCircleCardEmbed--2de1f";
export var SecondaryCircleCardImg = "cards-module--SecondaryCircleCardImg--0ef5f";
export var SecondaryCircleCardVideo = "cards-module--SecondaryCircleCardVideo--06dfe";
export var SecondaryCircleCrad = "cards-module--SecondaryCircleCrad--376f3";
export var SecondarySquareCard = "cards-module--SecondarySquareCard--85661";
export var SecondarySquareCardEmbed = "cards-module--SecondarySquareCardEmbed--22c1e";
export var SecondarySquareCardImg = "cards-module--SecondarySquareCardImg--f1b6e";
export var SecondarySquareCardVideo = "cards-module--SecondarySquareCardVideo--48cda";
export var StoryCrad = "cards-module--StoryCrad--573cc";
export var StoryCradImage = "cards-module--StoryCradImage--b7c1f";
export var StoryCradText = "cards-module--StoryCradText--8c941";
export var StoryCradTitleText = "cards-module--StoryCradTitleText--3d2c5";
export var UseCaseBannerCard = "cards-module--UseCaseBannerCard--19772";
export var caseStudyCard = "cards-module--caseStudyCard--d8cb8";
export var caseStudyCardDesc = "cards-module--caseStudyCardDesc--1059f";
export var caseStudyCardTitle = "cards-module--caseStudyCardTitle--7eeaa";
export var caseStudyExplore = "cards-module--caseStudyExplore--82158";
export var circleCard = "cards-module--circleCard--ca9c6";
export var clientCardActive = "cards-module--clientCardActive--9069f";
export var clientsCircleCard = "cards-module--clientsCircleCard--42925";
export var icon = "cards-module--icon--e5091";
export var iconContainer = "cards-module--iconContainer--ee314";
export var imgContainer = "cards-module--imgContainer--7ab42";
export var isPlatform = "cards-module--isPlatform--b5847";
export var minutes = "cards-module--minutes--e0ed0";
export var moveUp1 = "cards-module--moveUp1--956aa";
export var moveUp2 = "cards-module--moveUp2--2f286";
export var moveUp3 = "cards-module--moveUp3--059dc";
export var moveUp4 = "cards-module--moveUp4--700f5";
export var moveUp5 = "cards-module--moveUp5--6b868";
export var moveUp6 = "cards-module--moveUp6--75a8b";
export var moveUp7 = "cards-module--moveUp7--eb7ae";
export var overlayDiv = "cards-module--overlayDiv--915c7";
export var peopleCardContainer = "cards-module--peopleCardContainer--d400e";
export var peopleCardImgDiv = "cards-module--peopleCardImgDiv--4efce";
export var readTime = "cards-module--readTime--1f948";