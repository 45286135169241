import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "./typography.css";
import KpointPlayerProvider from "../../../context/KpointPlayerProvider";
import { ObserverProvider } from "../../../context/ObserverContext";

const Layout = ({ children }) => {
  return (
    <>
      <ObserverProvider>
        <KpointPlayerProvider>
          <div className="layout">
            <Header />
            <main>{children}</main>
            <Footer />
          </div>
          <div id="portal-root"></div>
        </KpointPlayerProvider>
      </ObserverProvider>
    </>
  );
};

export default Layout;
