import React, { useState, useRef, useEffect } from 'react';
import * as styles from './EmailGate.module.css';
import {baseUrl, isAllowedDomain} from '../../utils/helpers/helper';

const EmailGate = ({ onClose,playerRef }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const emailInputRef = useRef(null);
    const submitButtonRef = useRef(null);
    const modalRef = useRef(null);
    const timeoutRef = useRef();

    useEffect(() => {
        emailInputRef.current.focus();
        const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
        const firstFocusableElement = modalRef.current.querySelectorAll(focusableElements)[0];
        const focusableContent = modalRef.current.querySelectorAll(focusableElements);
        const lastFocusableElement = focusableContent[focusableContent.length - 1];

        const trapFocus = (event) => {
            let isTabPressed = event.key === 'Tab' || event.keyCode === 9;

            if (!isTabPressed) {
                return;
            }

            if (event.shiftKey) {
                if (document.activeElement === firstFocusableElement) {
                    lastFocusableElement.focus();
                    event.preventDefault();
                }
            } else {
                if (document.activeElement === lastFocusableElement) {
                    firstFocusableElement.focus();
                    event.preventDefault();
                }
            }
        };

        modalRef.current.addEventListener('keydown', trapFocus);

        return () => {
            // Clear the specific timeout when the component unmounts
            clearTimeout(timeoutRef.current);
            modalRef?.current?.removeEventListener('keydown', trapFocus);

        };
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        submitButtonRef.current.innerText = "Submitting...";
        setIsSubmitted(true);

        if (!email) {
            setError("Email is required");
            submitButtonRef.current.innerText = "Submit";
            setIsSubmitted(false);
            return;
        }
        if(!isAllowedDomain(email))
        {
            setError("Please enter a valid work email");
            submitButtonRef.current.innerText = "Submit";
            setIsSubmitted(false);
            return;
        }
        try {
            const data={Email:email}
            if(playerRef.current){
                data.videoId=playerRef.current?.info?.personalizedData?.videoId;
                data.videoTitle=playerRef.current?.info?.personalizedData?.videoTitle;
                data.viewToken= playerRef.current?.info?.viewToken;
                data.mailTo= playerRef.current?.info?.personalizedData?.mailTo??"";
            }
            const response = await fetch(`${baseUrl}/api/schedule-demos`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ data }),
            });

            if (response.ok) {
                submitButtonRef.current.innerText = "Submitted";
                setSuccess("Email submitted successfully!");
                timeoutRef.current = setTimeout(() => {
                    onClose();
                }, 2000);
            } else {
                const errorMessage = await response.text();
                setError(errorMessage);
                setIsSubmitted(false);
            }
        } catch (error) {
            console.error("Error submitting email:", error);
            setError(error.message);
            setIsSubmitted(false);
        } finally {
            submitButtonRef.current.innerText = "Submit";
        }
    };

    const handleInputChange = (e) => {
        setEmail(e.target.value);
        if (isSubmitted) {
            setIsSubmitted(false);
            setError(null);
            setSuccess(null);
        }
    };

    return (
        <div className={styles.container} ref={modalRef} aria-modal="true" role="dialog">
            <div className={styles.content}>
                <button className={styles.closeButton} onClick={onClose} aria-label="Close">
                    ×
                </button>
                <h2 className={styles.title}>Contact Us
                </h2>
                <form onSubmit={handleSubmit} className={styles.emailForm}>
                    <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleInputChange}
                        className={styles.emailInput}
                        placeholder="Please enter your work email"
                        ref={emailInputRef}
                        required
                        aria-required="true"
                        aria-invalid={error ? "true" : "false"}
                        aria-describedby="email-error"
                    />
                    <button ref={submitButtonRef} className={styles.submitButton} type="submit" disabled={isSubmitted}>
                        Submit
                    </button>
                    {/* Add a div to act as a container for error/success messages */}
                    <div className={styles.messageContainer}>
                        {error && <p id="email-error" className={styles.error}>{error}</p>}
                        {success && <p className={styles.success}>{success}</p>}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EmailGate;
