import React from "react";
import * as styles from "./css/ConfirmationPopUp.module.css";

const ConfirmationPopUp = ({ handelPop }) => {
  const handelPopUp = (state) => {
    handelPop(state);
  };
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <div className={styles.message}>
            Are you sure you want to close? <br /> Your data will be lost
          </div>
          <div className={styles.confirmationBtns}>
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handelPopUp("yes");
              }}
            >
              Yes
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handelPopUp("no");
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationPopUp;
