// ObserverContext.js
import React, { createContext, useContext, useEffect, useRef } from "react";

const ObserverContext = createContext({
  observe: () => {},
  unobserve: () => {},
});

export const ObserverProvider = ({ children }) => {
  // Refs for observers
  const inViewObserver = useRef(null);
  const loadObserver = useRef(null);
  const elementsToObserve = useRef([]);

  useEffect(() => {
    // Initialize inViewObserver
    inViewObserver.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Call the callback associated with the entry's target
            entry.target.inViewCallback?.(true);
          } else {
            entry.target.inViewCallback?.(false);
          }
        });
      },
      {
        threshold: 0.7,
      }
    );

    // Initialize loadObserver
    loadObserver.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Remove from loadObserver to prevent re-triggering
            loadObserver.current.unobserve(entry.target);
            // Trigger load logic
            entry.target.loadCallback?.(true);
          }
        });
      },
      {
        threshold: 0.1,
        rootMargin: `${
          typeof window !== "undefined" ? window.innerHeight : 0
        }px 0px ${
          typeof window !== "undefined" ? window.innerHeight : 0
        }px 0px`,
      }
    );
    // Observe elements that were added before observers were initialized
    elementsToObserve.current.forEach((element) => {
      loadObserver.current.observe(element);
      inViewObserver.current.observe(element);
    });
    elementsToObserve.current = [];
    return () => {
      inViewObserver.current?.disconnect();
      loadObserver.current?.disconnect();
    };
  }, []);

  // Function to start observing an element with both observers
  const observe = (element, inViewCallback, loadCallback) => {
    if (element) {
      element.inViewCallback = inViewCallback;
      element.loadCallback = loadCallback;

      if (loadObserver.current) {
        loadObserver.current.observe(element);
        inViewObserver.current.observe(element);
      } else {
        // If observers are not initialized yet, store element to observe it later
        elementsToObserve.current.push(element);
      }
    }
  };

  // Function to stop observing an element
  const unobserve = (element) => {
    inViewObserver.current.unobserve(element);
    loadObserver.current.unobserve(element);
  };

  return (
    <ObserverContext.Provider value={{ observe, unobserve }}>
      {children}
    </ObserverContext.Provider>
  );
};

export const useObserver = () => useContext(ObserverContext);
