import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const GetMediaFromSrc = () => {
  const data = useStaticQuery(graphql`
    query {
      allImages: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF, JPG, PNG]
              layout: CONSTRAINED # Specify the layout here, choose the appropriate one for your use case
            )
          }
          extension
          publicURL
        }
      }
    }
  `);
  return data;
};

export function FetchImage({ mediaName, folder, className, styles }) {
  const data = GetMediaFromSrc();

  const imagePath = folder ? `${folder}/${mediaName}` : mediaName;

  const image = data.allImages.nodes.find((node) => {
    if (node.relativePath === imagePath) {
      return node;
    }
  });

  if (!image) {
    // console.error(`media not found: ${imagePath}`);
    return null;
  }
  const imgData = image.childImageSharp
    ? image.childImageSharp.gatsbyImageData
    : null;
  return (
    <GatsbyImage
      style={styles}
      image={imgData}
      alt={mediaName || "img"}
      className={className}
    />
  );
}

export const getPublicUrl = (folder, name) => {
  const data = GetMediaFromSrc();
  const imagePath = folder ? `${folder}/${name}` : name;
  const image = data.allImages.nodes.find(
    (node) => node.relativePath === imagePath
  );

  if (!image) {
    return null;
  }
  return image.publicURL;
};
