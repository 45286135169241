import { useLocation } from "@gatsbyjs/reach-router";
import React, { useState, useEffect } from "react";
export const baseUrl =
  // process.env.NODE_ENV === "development"
  //   ? "http://localhost:1337"
  //   :
  // "http://localhost:1337";
  "https://website-backend.kpoint.com";
// : "https://kpoint-backend.supercode.in";

export const combineObjects = (arr) => {
  try {
    if (!arr) return arr;
    const combined = {};

    arr.forEach((obj) => {
      for (const [key, value] of Object.entries(obj)) {
        if (!combined[key]) {
          combined[key] = value;
        } else {
          combined[key] = combined[key].concat(value);
        }
      }
    });

    return combined;
  } catch (error) {
    console.error("error: ", error);
  }
};

export const handleShare = ({ title, text, url, onSucces, onError }) => {
  if (navigator.share) {
    navigator
      .share({
        title,
        text,
        url,
      })
      .then(() => onSucces())
      .catch((error) => onError(error));
  } else {
    navigator.clipboard
      .writeText(url)
      .then(() =>
        onSucces
          ? onSucces("Link has been copied")
          : alert("Link has been copied")
      );
  }
};

export const handleCopy = ({ text, onSuccess, onError } = {}) => {
  try {
    navigator.clipboard
      .writeText(text || window.location.href)
      .then(() =>
        onSuccess
          ? onSuccess("Link has been copied")
          : alert("Link has been copied")
      );
  } catch (error) {
    onError ? onError() : console.error("error", error);
  }
};

export const extractAttributes = (inputCode) => {
  const attributeRegex = /(\w+)\s*=\s*(['"])(.*?)\2/g;
  const result = {};

  let match;
  while ((match = attributeRegex.exec(inputCode)) !== null) {
    const attributeName = match[1];
    const attributeValue = match[3];
    result[attributeName] = attributeValue;
  }

  return result;
};

const NotallowedFreeDomains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "outlook.com",
  "aol.com",
];
export const isValidEmailFormat = (email) => {
  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,4}$/;

  return emailRegex.test(email);
};

export const isAllowedDomain = (email) => {
  if (isValidEmailFormat(email)) {
    // Extract domain from the email
    const domain = email.split("@")[1];

    // Check if the domain is in the list of allowed domains
    return !NotallowedFreeDomains.includes(domain);
  } else {
    return false;
  }
};

let GMap;
export const GmapData = async ({ lat = 18.53658, lng = 73.7989, mapRef }) => {
  // Request needed libraries.
  //@ts-ignore
  const { Map } = await window.google.maps.importLibrary("maps");
  const { AdvancedMarkerElement } = await window.google.maps.importLibrary(
    "marker"
  );

  // The map, centered at Uluru
  GMap = new Map(mapRef, {
    zoom: 15,
    center: { lat, lng },
    mapId: "kPoint_map",
  });

  // The marker, positioned at Uluru
  const marker = new AdvancedMarkerElement({
    map: GMap,
    position: { lat, lng },
    title: "KPoint-building",
  });
};

export const ScreenSize = {
  isDesktop: () => typeof window !== "undefined" && window.innerWidth >= 1100,
  isLaptop: () =>
    typeof window !== "undefined" &&
    window.innerWidth > 1100 &&
    window.innerWidth <= 1500,
  isTablet: () =>
    typeof window !== "undefined" &&
    window.innerWidth >= 768 &&
    window.innerWidth <= 1200,
  isMobile: () => typeof window !== "undefined" && window.innerWidth < 768,
  isWidth: (width) =>
    typeof window !== "undefined" && window.innerWidth <= width,
};

export const toCamelCase = (str) => {
  if (!str) return "";

  return str
    .split(/[\s-]+/) // Split on spaces and hyphens
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      // Capitalize the first letter, lowercase the rest
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
};

export const scheduleDemo = async ({ data, fromSuccess, formError }) => {
  try {
    const response = await fetch(`${baseUrl}/api/schedule-demos`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data,
      }),
    });

    if (response.ok) {
      // Handle successful submission
      fromSuccess(true);
    } else {
      // Handle error
      const errorMessage = await response.text();
      const { error } = JSON.parse(errorMessage);
      if (error) {
        if (error.message.includes("Email")) {
          formError("Please enter a valid business email Id");
        } else {
          formError("Please enter all the fields");
        }
      }
    }
  } catch (error) {
    // Handle network or other errors
    console.error("Error creating contact:", error.message);
  }
};

export const IsEmbedded = (template) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const queryParamsObject = {};
  queryParams.forEach((value, key) => {
    queryParamsObject[key] = value;
  });
  return template
    ? queryParamsObject?.type === template
      ? true
      : false
    : location.pathname.includes("/embed") ||
        location.pathname.includes("/forms");
};

/**
 * Renders an SVG icon dynamically imported based on provided parameters.
 * @param {object} props - Component props.
 * @param {object} props.style - Inline CSS styles for the SVG icon.
 * @param {string} props.IconName - Name of the SVG icon file to import.
 * @param {string} [props.IconType="light"] - Type of the icon set (e.g., "light", "regular", "solid").
 * @param {object} rest - Additional props to pass to the SVG element.
 * @returns {JSX.Element} - Rendered SVG icon component.
 */
export const RenderSvg = React.memo(
  ({ style, IconName, IconType = "light", ...rest }) => {
    // Extracting color from style props and setting default styles
    const { color, ...restStyles } = style || {};
    const defaultStyles = {
      height: "30px",
      width: "auto",
      fill: color || "black",
      ...restStyles,
    };

    // Importing the SVG dynamically using React.lazy
    const Font = React.lazy(() =>
      import(`../../fontAwasome/svgs/${IconType}/${IconName}.svg`)
    );

    // Rendering the SVG icon with suspense fallback
    return (
      <React.Suspense fallback={<>&#x3f;</>}>
        <Font {...rest} style={defaultStyles} />
      </React.Suspense>
    );
  },
  (preProps, nextprops) => {
    const isEqual =
      preProps?.IconName === nextprops?.IconName &&
      preProps?.IconType === nextprops?.IconType &&
      JSON.stringify(preProps?.style) === JSON.stringify(nextprops?.style);
    return isEqual;
  }
);

/*render gif code */
/**
 * Renders a GIF image dynamically imported based on provided parameters.
 * @param {object} props - Component props.
 * @param {object} props.style - Inline CSS styles for the GIF image.
 * @param {string} props.gifName - Name of the GIF file to import.
 * @param {string} [props.gifFolder="animations"] - Folder containing the GIF files.
 * @param {object} rest - Additional props to pass to the img element.
 * @returns {JSX.Element} - Rendered GIF image component.
 */
export const RenderGif = React.memo(
  ({ style, gifName, gifFolder = "animations", ...rest }) => {
    const [gifSrc, setGifSrc] = useState(null);

    useEffect(() => {
      const importGif = async () => {
        try {
          const { default: src } = await import(
            `../../fontAwasome/gifs/${gifFolder}/${gifName}.gif`
          );
          setGifSrc(src);
        } catch (error) {
          console.error(`Error loading GIF: ${gifName}`, error);
        }
      };

      importGif();
    }, [gifName, gifFolder]);

    const defaultStyles = {
      maxWidth: "100%",
      height: "auto",
      ...style,
    };

    if (!gifSrc) {
      return <div></div>;
    }

    return <img src={gifSrc} style={defaultStyles} alt={gifName} {...rest} />;
  },
  (prevProps, nextProps) => {
    return (
      prevProps.gifName === nextProps.gifName &&
      prevProps.gifFolder === nextProps.gifFolder &&
      JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
    );
  }
);

export const playORPauseVideo = ({ id, status }) => {
  let homepageBannerId = document.getElementById(id);
  if (homepageBannerId && status) {
    const isMuted = homepageBannerId.getAttribute("data-muted");
    homepageBannerId.setAttribute(
      "video-status",
      status === "open" ? "pause" : "play"
    );
    if (status === "open")
      homepageBannerId.setAttribute("video-isMuted", isMuted);
    homepageBannerId.click();
  }
};
