// extracted by mini-css-extract-plugin
export var blueGradient = "backgroundWave-module--blueGradient--fefd5";
export var bluebg = "backgroundWave-module--bluebg--192bf";
export var blur = "backgroundWave-module--blur--a9eb3";
export var embedScript = "backgroundWave-module--embedScript--80d77";
export var grad = "backgroundWave-module--grad--f3192";
export var greenGradient = "backgroundWave-module--greenGradient--7a94e";
export var greenbg = "backgroundWave-module--greenbg--8e9f3";
export var greyGradient = "backgroundWave-module--greyGradient--b175e";
export var greybg = "backgroundWave-module--greybg--ae558";
export var ocean = "backgroundWave-module--ocean--f2556";
export var redGradient = "backgroundWave-module--redGradient--590c4";
export var redbg = "backgroundWave-module--redbg--da712";
export var swell = "backgroundWave-module--swell--76497";
export var wave = "backgroundWave-module--wave--2ee89";
export var wave1 = "backgroundWave-module--wave1--181cd";
export var wave2 = "backgroundWave-module--wave2--edd9f";
export var wave3 = "backgroundWave-module--wave3--5325b";
export var waves = "backgroundWave-module--waves--46e8e";