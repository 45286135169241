import React from "react";
import { parseDocument, DomUtils } from "htmlparser2";
import KpointPlayer from "./KpointPlayer";

export const KpointPlayerFromEmbedCode = ({ embedCode, ...rest }) => {
  // Use DOMParser to parse the HTML string
  const document = parseDocument(embedCode);
  const divElement = DomUtils.findOne(
    (el) => el.type === "tag" && el.name === "div",
    document.children
  );
  if (!divElement) {
    return null;
  }
  const videoId = divElement.attribs["data-kvideo-id"];
  const host = divElement.attribs["data-video-host"];
  const params = JSON.parse(divElement.attribs["data-video-params"] || "{}");
  const externalWidgetsConfig = JSON.parse(
    divElement.attribs["data-widgets-config"] || "{}"
  );
  const packageId = divElement.attribs["data-package-id"];
  const personalizationInfo = divElement.attribs["data-personalization-info"];
  const thumbnailPath = divElement.attribs["data-thumbnail-path"];
  const isVerticalVideo = divElement.attribs["data-ar"] === "9:16";
  const horizontalVideoId = divElement.attribs["data-horizontal-video-id"];
  const verticalVideoId = divElement.attribs["data-vertical-video-id"];
  const fakeVideoId = divElement.attribs["data-fake-video-id"];
  const fakeVerticalVideoId = divElement.attribs["data-fake-vertical-video-id"];
  const fakeHorizontalVideoId =
    divElement.attribs["data-fake-horizontal-video-id"];

  const attributes = {
    videoId,
    host,
    params,
    externalWidgetsConfig,
    packageId,
    personalizationInfo,
    thumbnailPath,
    isVerticalVideo,
    horizontalVideoId,
    verticalVideoId,
    fakeVideoId,
    fakeHorizontalVideoId,
    fakeVerticalVideoId,
    ...rest,
  };

  return <KpointPlayer {...attributes} />;
};
